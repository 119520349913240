import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TagPage = () => (
  <Layout pageTitle="Tag">
    <SEO title="Tag" keywords={['Raspberry Pi, Developer,C++, Arduino, Python']}/>
    <p>You're curious one, I like you.</p>
    
    
  </Layout>
)

export default TagPage
